import React, { useState, useEffect } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { FaLightbulb, FaBrain, FaAtom, FaChalkboardTeacher, FaFlask, FaMicroscope } from "react-icons/fa";
import { Button } from "antd";

const EducationTopicPage = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [showQuiz, setShowQuiz] = useState(false);
    const [currentQuizQuestion, setCurrentQuizQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [showError, setShowError] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [currentTopic, setCurrentTopic] = useState(0);

    const quizTopics = [
        {
            title: "Vật Lý Lượng Tử",
            questions: [
                {
                    question: "Cơ học lượng tử chủ yếu nghiên cứu về cái gì?",
                    options: [
                        "Hành vi của vật chất ở quy mô nguyên tử",
                        "Cơ học cổ điển",
                        "Vật thể thiên văn",
                        "Mô hình thời tiết"
                    ],
                    correctAnswer: 0
                },
                {
                    question: "Tính chất sóng-hạt là gì?",
                    options: [
                        "Lý thuyết về sóng đại dương",
                        "Vật chất thể hiện cả tính chất sóng và hạt",
                        "Khái niệm âm nhạc",
                        "Phương trình toán học"
                    ],
                    correctAnswer: 1
                }
            ]
        },
        {
            title: "Sinh Học Phân Tử",
            questions: [
                {
                    question: "DNA là viết tắt của?",
                    options: [
                        "Axit Deoxyribo Nucleic",
                        "Axit Digital Numeric",
                        "Digital Network Access",
                        "Dynamic Nucleic Array"
                    ],
                    correctAnswer: 0
                },
                {
                    question: "Quá trình tổng hợp protein xảy ra ở đâu trong tế bào?",
                    options: [
                        "Nhân tế bào",
                        "Ti thể",
                        "Ribosome",
                        "Màng tế bào"
                    ],
                    correctAnswer: 2
                }
            ]
        },
        {
            title: "Hóa Học Hữu Cơ",
            questions: [
                {
                    question: "Ancol là hợp chất có nhóm chức nào?",
                    options: [
                        "OH",
                        "COOH",
                        "NH2",
                        "CHO"
                    ],
                    correctAnswer: 0
                },
                {
                    question: "Phản ứng este hóa là phản ứng giữa?",
                    options: [
                        "Axit + Bazơ",
                        "Axit + Ancol",
                        "Ancol + Bazơ",
                        "Ancol + Nước"
                    ],
                    correctAnswer: 1
                }
            ]
        }
    ];

    const educationalContent = {
        title: "Giới Thiệu về Vật Lý Lượng Tử",
        sections: [
            {
                id: 1,
                title: "Cơ Bản về Cơ Học Lượng Tử",
                content: "Cơ học lượng tử là một lý thuyết cơ bản trong vật lý mô tả hành vi của vật chất và năng lượng ở quy mô nguyên tử và hạ nguyên tử. Lĩnh vực thú vị này thách thức trực giác hàng ngày của chúng ta về cách thế giới hoạt động.",
                detailedContent: "Cơ học lượng tử đại diện cho một trong những thay đổi sâu sắc nhất trong hiểu biết của chúng ta về thế giới vật lý. Không giống như vật lý cổ điển, cơ học lượng tử cho thấy một thế giới nơi các hạt có thể tồn tại đồng thời ở nhiều trạng thái và có thể được kết nối tức thì bất kể khoảng cách.",
                image: "images.unsplash.com/photo-1635070041078-e363dbe005cb",
                icon: <FaAtom className="text-purple-500 text-2xl" />
            },
            {
                id: 2,
                title: "Tính Chất Sóng-Hạt",
                content: "Tính chất sóng-hạt cho thấy tất cả vật chất và năng lượng đều thể hiện cả tính chất sóng và hạt. Nguyên lý này là một trong những khái niệm chính thể hiện bản chất lượng tử của vũ trụ.",
                detailedContent: "Khái niệm về tính chất sóng-hạt xuất hiện từ các thí nghiệm khác nhau vào đầu thế kỷ 20. Hiện tượng này cho thấy ánh sáng và vật chất có thể hiển thị đặc điểm của cả sóng và hạt.",
                image: "images.unsplash.com/photo-1509228468518-180dd4864904",
                icon: <FaBrain className="text-blue-500 text-2xl" />
            },
            {
                id: 3,
                title: "Sự Rối Lượng Tử",
                content: "Sự rối lượng tử là hiện tượng trong đó hai hoặc nhiều hạt trở nên tương quan theo cách mà trạng thái lượng tử của mỗi hạt không thể được mô tả độc lập.",
                detailedContent: "Sự rối lượng tử là một trong những đặc điểm bí ẩn và mạnh mẽ nhất của cơ học lượng tử. Khi các hạt bị rối, trạng thái lượng tử của chúng được kết nối bất kể khoảng cách giữa chúng.",
                image: "images.unsplash.com/photo-1507413245164-6160d8298b31",
                icon: <FaFlask className="text-green-500 text-2xl" />
            },
            {
                id: 4,
                title: "Máy Tính Lượng Tử",
                content: "Máy tính lượng tử sử dụng các nguyên lý của cơ học lượng tử để thực hiện các phép tính mà máy tính cổ điển không thể thực hiện được.",
                detailedContent: "Máy tính lượng tử sử dụng các bit lượng tử (qubit) có thể tồn tại ở nhiều trạng thái đồng thời. Điều này cho phép chúng thực hiện một số phép tính nhanh hơn theo cấp số mũ so với máy tính cổ điển.",
                image: "images.unsplash.com/photo-1635070041078-e363dbe005cb",
                icon: <FaMicroscope className="text-red-500 text-2xl" />
            }
        ]
    };

    useEffect(() => {
        setCurrentTopic(Math.floor(Math.random() * quizTopics.length));
    }, []);

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 0 && !educationalContent.sections.some(section =>
            section.title.toLowerCase().includes(query.toLowerCase()) ||
            section.content.toLowerCase().includes(query.toLowerCase())
        )) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    };

    const handleAnswerSubmit = (selectedAnswer) => {
        if (selectedAnswer === quizTopics[currentTopic].questions[currentQuizQuestion].correctAnswer) {
            setScore(score + 1);
        }
        if (currentQuizQuestion < quizTopics[currentTopic].questions.length - 1) {
            setCurrentQuizQuestion(currentQuizQuestion + 1);
        } else {
            setShowScore(true);
        }
    };

    const resetQuiz = () => {
        setShowQuiz(false);
        setShowScore(false);
        setCurrentQuizQuestion(0);
        setScore(0);
        setCurrentTopic(Math.floor(Math.random() * quizTopics.length));
    };

    const filteredSections = educationalContent.sections.filter(section =>
        searchQuery === "" ||
        section.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        section.content.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 p-6">
            <div className="max-w-6xl mx-auto">
                <motion.h1
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-8 text-center"
                >
                    {educationalContent.title}
                </motion.h1>

                <div className="relative mb-8">
                    <input
                        type="text"
                        placeholder="Tìm kiếm trong chủ đề..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className="w-full p-4 rounded-lg border border-purple-300 shadow-lg focus:ring-2 focus:ring-purple-400 focus:border-transparent bg-white/80 backdrop-blur-sm"
                        aria-label="Tìm kiếm trong chủ đề"
                    />
                    <FiSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-purple-400" />

                    <AnimatePresence>
                        {showError && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0 }}
                                className="absolute top-full mt-2 text-red-500 text-sm font-medium"
                            >
                                Không tìm thấy kết quả cho từ khóa của bạn
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                    {filteredSections.map((section) => (
                        <motion.div
                            key={section.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            whileHover={{ scale: 1.02 }}
                            className="bg-white/80 backdrop-blur-sm rounded-xl shadow-xl overflow-hidden hover:shadow-2xl transition-all duration-300 cursor-pointer"
                            onClick={() => setSelectedArticle(section)}
                        >
                            <div className="relative">
                                <img
                                    src={`https://${section.image}`}
                                    alt={section.title}
                                    className="w-full h-48 object-cover"
                                    onError={(e) => {
                                        e.target.src = "https://images.unsplash.com/photo-1518818419601-72c8673f5852";
                                    }}
                                />
                                <div className="absolute top-4 right-4 bg-white/90 p-2 rounded-full shadow-lg">
                                    {section.icon}
                                </div>
                            </div>
                            <div className="p-6">
                                <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center gap-2">
                                    {section.title}
                                </h2>
                                <p className="text-gray-600 leading-relaxed">{section.content}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>

                <AnimatePresence>
                    {selectedArticle && (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
                            onClick={() => setSelectedArticle(null)}
                        >
                            <motion.div
                                initial={{ y: 50 }}
                                animate={{ y: 0 }}
                                className="bg-white rounded-xl max-w-3xl max-h-[80vh] overflow-y-auto p-8"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div className="flex justify-between items-start mb-6">
                                    <h2 className="text-3xl font-bold text-gray-800 flex items-center gap-3">
                                        {selectedArticle.icon}
                                        {selectedArticle.title}
                                    </h2>
                                    <button
                                        onClick={() => setSelectedArticle(null)}
                                        className="p-2 hover:bg-gray-100 rounded-full"
                                    >
                                        <FiX className="text-2xl" />
                                    </button>
                                </div>
                                <img
                                    src={`https://${selectedArticle.image}`}
                                    alt={selectedArticle.title}
                                    className="w-full h-64 object-cover rounded-lg mb-6"
                                    onError={(e) => {
                                        e.target.src = "https://images.unsplash.com/photo-1518818419601-72c8673f5852";
                                    }}
                                />
                                <p className="text-gray-600 leading-relaxed mb-4">
                                    {selectedArticle.content}
                                </p>
                                <p className="text-gray-700 leading-relaxed">
                                    {selectedArticle.detailedContent}
                                </p>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="bg-white/80 backdrop-blur-sm rounded-xl shadow-xl p-8 mb-8"
                >
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center gap-2">
                        <FaLightbulb className="text-yellow-500" />
                        Kiểm Tra Kiến Thức: {quizTopics[currentTopic].title}
                    </h2>
                    {!showQuiz && !showScore ? (
                        <button
                            onClick={() => setShowQuiz(true)}
                            className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-8 py-4 rounded-lg hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105 shadow-lg"
                        >
                            Bắt Đầu Kiểm Tra
                        </button>
                    ) : showScore ? (
                        <div className="text-center space-y-4">
                            <h3 className="text-2xl font-bold text-gray-800">
                                Điểm của bạn: {score}/{quizTopics[currentTopic].questions.length}
                            </h3>
                            <button
                                onClick={resetQuiz}
                                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-8 py-4 rounded-lg hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105 shadow-lg"
                            >
                                Thử Lại
                            </button>
                        </div>
                    ) : (
                        <div className="space-y-6">
                            <h3 className="text-xl text-gray-700 font-medium">
                                Câu hỏi {currentQuizQuestion + 1} trong {quizTopics[currentTopic].questions.length}:
                                {quizTopics[currentTopic].questions[currentQuizQuestion].question}
                            </h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {quizTopics[currentTopic].questions[currentQuizQuestion].options.map((option, index) => (
                                    <motion.button
                                        key={index}
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        onClick={() => handleAnswerSubmit(index)}
                                        className="bg-white p-4 rounded-lg hover:bg-purple-50 transition-colors duration-300 text-left shadow-md hover:shadow-lg"
                                    >
                                        {option}
                                    </motion.button>
                                ))}
                            </div>
                        </div>
                    )}
                </motion.div>
            </div>
        </div>
    );
};

export default EducationTopicPage;