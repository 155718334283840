import { Navigate, Route, Routes } from "react-router-dom";
// import MenuBar from "../layout/menuBar";
import SystemAdminDashboard from "../components/LayoutCommon/LayoutAdmin";
import Database from "../pages/Admin/database";

const AdminRoute = () => {
    const routes = [
        // { path: "/admin", component: <PuzzleGame /> },
        { path: "/database", component: <Database /> },
        { path: "/", component: <Database /> },
    ];

    return (
        <Routes>
            <Route element={<SystemAdminDashboard />}>
                {/* Thêm các route khác cho Admin ở đây */}
                {routes.map((item, key) => (
                    <Route path={item.path} element={item.component} key={key} />
                ))}
            </Route>
        </Routes>
    )
}

export default AdminRoute;