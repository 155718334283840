import { BulbOutlined } from '@ant-design/icons';
import { Badge, Button, Flex, List, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { getApi, postApi } from '../../services/fetchData';
// import { useAuthStore } from "../../store/authStore";
import { useDispatch } from 'react-redux';
import { setAppLoading } from '../../hooks/store/userSlice';
import { useQuery } from 'react-query';
import { getQuestions, updateLearn } from '../../services/commonApiRequest';

const { Text } = Typography;

const CrossWord = () => {
  const [completedRows, setCompletedRows] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  let { catergory_id } = location?.state || {};

  const {
    data: data,
    refetch,
    isLoading,
  } = useQuery(['data', catergory_id], () => getQuestions({ catergory_id: catergory_id }), {
    enabled: catergory_id !== null,
  });

  const crossWordData = useMemo(() => {
    if (data?.data) {
      const { options, question, link_youtube, index_word } = data?.data?.[0];

      const listIndex = index_word?.split(',');

      const mapData = options?.map((item, index) => {
        return {
          answer: item,
          question: question[index],
          indexAnswer: Number(listIndex[index]),
        };
      });

      return mapData;
    }
    return [];
  }, [data]);

  const calSpace = (indexAnswer) => {
    let maxlength = 0;
    for (var j = 0; j < crossWordData.length; j++) {
      var indexAnswerNumber = Number(crossWordData[j].indexAnswer);
      if (maxlength < indexAnswerNumber) {
        maxlength = indexAnswerNumber;
      }
    }
    return (maxlength - indexAnswer + 1) * 50;
  };

  const renderRowItem = (item, index) => {
    let crossInputs = [];
    let spaceLeft = calSpace(item.indexAnswer);

    for (let index = 1; index <= item?.answer?.length; index++) {
      crossInputs.push(index);
    }

    const handleOpenRow = async (rowId) => {
      let currentAnswerArr = document.getElementById(rowId).querySelectorAll('input');
      let currentAnswer = '';
      if (currentAnswerArr) {
        currentAnswerArr.forEach((item, index) => {
          if (item.value) {
            currentAnswer += item.value.toUpperCase();
          }
        });
      }
      if (currentAnswer === crossWordData[index].answer) {
        document.getElementById(`cross-row${index}`).className = '';
        document.getElementById(`cross-row${index}`).classList.add('cross-correct');
        const newCompletedRows = [...completedRows, index];
        setCompletedRows(newCompletedRows);
        if (newCompletedRows.length === crossWordData.length) {
          // All rows are completed, show the button
          // You can add your logic here
          console.log('All rows completed!');

          dispatch(setAppLoading(true));
          await updateLearn({ catergory_id: catergory_id, point: 100 });
          dispatch(setAppLoading(false));

          setIsCompleted(true);
        }
      } else {
        document.getElementById(`cross-row${index}`).className = '';
        document.getElementById(`cross-row${index}`).classList.add('cross-wrong');
      }
      // if (currentAnswer === item.answer) {
      //   document.getElementById(rowId).className = "";
      //   document.getElementById(rowId).classList.add("cross-correct");
      // } else {
      //   document.getElementById(rowId).className = "";
      //   document.getElementById(rowId).classList.add("cross-wrong");
      // }
    };
    const handleOnKeyUp = (event) => {
      if (event.keyCode === 8 || event.keyCode === 32 || event.keyCode === 191 || event.keyCode === 190) {
        return;
      }
      try {
        var currentTabindexElement = event.target.getAttribute('tabIndex')?.toString()?.split('-');
        var nextEle = `${currentTabindexElement[0]}-${Number(currentTabindexElement[1]) + 1}`;
        document.querySelector(`[tabIndex=${nextEle}]`).focus();
      } catch { }
    };

    return (
      <div id={`cross-row${index}`}>
        <Flex gap={'middle'} vertical={false} align="center">
          {/* <Badge count={index + 1} color="#faad14" /> */}
          <div className={`flex flex-row`} style={{ marginLeft: spaceLeft }}>
            {crossInputs.map((inputIndex, index) => {
              return (
                <input
                  key={inputIndex}
                  className={`crossword-letter ${item.indexAnswer === index + 1 ? 'crossword-letter-keyword' : ''}`}
                  // onkeyup="skipToNext(this);"
                  type="text"
                  minLength="1"
                  maxLength="1"
                  pattern="^[A-Za-z]{1}$"
                  required="required"
                  onKeyUp={handleOnKeyUp}
                  // value=""
                  tabIndex={`${item.answer}-${inputIndex}`}
                // data-type="hightlight"
                />
              );
            })}
          </div>
          <Button icon={<BulbOutlined />} onClick={() => handleOpenRow(`cross-row${index}`)}>
            Mở ô chữ {index + 1}
          </Button>
        </Flex>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center">
      <h4 className="mt-6 mb-8 text-4xl font-dancing">Vượt chướng ngại vật</h4>
      <div className="crossword-container">
        <List itemLayout="horizontal" dataSource={crossWordData} renderItem={renderRowItem} />
      </div>
      <div>
        <h5 className="my-6 text-3xl text-center font-dancing">Bộ Câu Hỏi</h5>
        <div className="mb-10">
          <List
            itemLayout="horizontal"
            dataSource={crossWordData}
            renderItem={(row, index) => (
              <li key={row.indexAnswer} className="my-2">
                <div className="flex flex-row">
                  <Badge count={index + 1} color="#faad14" />
                  <Text className="ml-2 text-2xl font-dancing">{row.question}</Text>
                </div>
              </li>
            )}
          />
          {isCompleted && (
            <div className="mx-auto mt-4 w-fit">
              <button
                className="p-4 mx-4 text-xl bg-green-300 rounded-full font-dancing"
                onClick={() => {
                  window.open('https://www.youtube.com/watch?v=NNpgdo7ENUE', '_blank');
                }}
              >
                Xem Tài Liệu
              </button>
              <button
                className="p-4 mx-4 text-xl bg-red-300 rounded-full font-dancing"
                onClick={() => {
                  navigate('/');
                }}
              >
                Về Trang Chủ
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CrossWord;
