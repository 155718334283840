export const LOGIN = "/auth/login";
export const REGISTER = "/auth/register";

export const LOGOUT = "/auth/logout";
export const UPDATE_INFO = "/admin/update";
export const VERIFICATION = "/auth/verification";
export const REFESH_SESSION = "/auth/refesh-session";

// common api
export const GET_CATEGORIES = "/common/get-categories";
export const GET_QUESTIONS = "/common/get-questions";
export const UPDATE_LEARN = "/common/update-learn";
export const GET_RANK = "/common/get-rank";

// cms
export const UPLOAD_FILE = "/cms/upload";
export const GET_ALL_FILE = "/cms/upload/getall";
export const DELETE_FILE = "/cms/upload/delete";
export const IMPORT_DATA = "/cms/import-data";
export const UPLOAD_DELETE = '/cms/upload/delete';
