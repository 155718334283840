import React, { useState, useEffect, useMemo } from 'react';
import { FiClock, FiMenu } from 'react-icons/fi';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { FaCircle, FaLightbulb } from 'react-icons/fa';
import { useQuery } from 'react-query';
// import { getApi } from "../../services/fetchData";
import { useDispatch } from 'react-redux';
import { setAppLoading } from '../../hooks/store/userSlice';
import { getQuestions, updateLearn } from '../../services/commonApiRequest';
import { useLocation, useNavigate } from 'react-router-dom';

const QuizGameEasy = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(15 * 60);
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showHints, setShowHints] = useState([]);
  const dispatch = useDispatch();

  // const questions = [
  //     {
  //         question: "Which planet is known as the Red Planet?",
  //         image: "images.unsplash.com/photo-1614728894747-a83421e2b9c9",
  //         options: ["Mars", "Venus", "Jupiter", "Saturn"],
  //         correctAnswer: "Mars",
  //         hint: "test",
  //         explanation: "Mars is called the Red Planet because of the reddish color of its surface, which contains iron oxide (rust)."
  //     },
  //     {
  //         question: "What is the largest mammal on Earth?",
  //         image: "images.unsplash.com/photo-1558696193-a1ae5c9eb4b4",
  //         options: ["Blue Whale", "African Elephant", "Giraffe", "Polar Bear"],
  //         correctAnswer: "Blue Whale",
  //         explanation: "The Blue Whale is the largest animal known to have ever existed, reaching lengths of up to 100 feet and weights of up to 200 tons."
  //     },
  //     {
  //         question: "Which gas do plants absorb from the atmosphere?",
  //         image: "images.unsplash.com/photo-1542601906990-b4d3fb778b09",
  //         options: ["Carbon Dioxide", "Oxygen", "Nitrogen", "Hydrogen"],
  //         correctAnswer: "Carbon Dioxide",
  //         explanation: "Plants absorb Carbon Dioxide during photosynthesis to produce glucose and oxygen as a byproduct."
  //     }
  // ];

  const navigate = useNavigate();
  const location = useLocation();
  let { catergory_id } = location?.state || {};

  const {
    data: data,
    refetch,
    isLoading,
  } = useQuery(['data', catergory_id], () => getQuestions({ catergory_id: catergory_id }), {
    enabled: catergory_id !== null,
  });

  const questions = useMemo(() => {
    if (data?.data) {
      setSelectedAnswers(new Array(data?.data?.length || 0).fill(null));
      setShowHints(new Array(data?.data?.length || 0).fill(false));
    }
    return data?.data || [];
  }, [data]);

  useEffect(() => {
    let interval;

    if (!isQuizSubmitted && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            submitQuiz();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timeLeft, isQuizSubmitted]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleAnswerSelect = (questionIndex, answer) => {
    if (!isQuizSubmitted) {
      const newAnswers = [...selectedAnswers];
      newAnswers[questionIndex] = answer;
      setSelectedAnswers(newAnswers);
    }
  };

  const toggleHint = (index) => {
    const newShowHints = [...showHints];
    newShowHints[index] = !newShowHints[index];
    setShowHints(newShowHints);
  };

  const submitQuiz = async () => {

    dispatch(setAppLoading(true));

    setIsQuizSubmitted(true);
    let newScore = 0;
    selectedAnswers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore += 1;
      }
    });

    await updateLearn({ catergory_id: catergory_id, point: Math.floor((newScore / questions.length) * 100) });

    dispatch(setAppLoading(false));

    setScore(newScore);
  };

  const resetQuiz = () => {
    setSelectedAnswers(new Array(questions.length).fill(null));
    setScore(0);
    setIsQuizSubmitted(false);
    setTimeLeft(15 * 60);
    setCurrentQuestion(0);
  };

  const scrollToQuestion = (index) => {
    setCurrentQuestion(index);
    setIsSidebarOpen(false);
    const element = document.getElementById(`question-${index}`);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  const getButtonClass = (questionIndex, option) => {
    if (!isQuizSubmitted && option === selectedAnswers[questionIndex]) {
      return 'bg-blue-200 border-blue-500';
    }
    if (!isQuizSubmitted) return '';
    if (option === questions[questionIndex].correctAnswer) {
      return 'bg-green-500 text-white';
    }
    if (option === selectedAnswers[questionIndex] && option !== questions[questionIndex].correctAnswer) {
      return 'bg-red-500 text-white';
    }
    return '';
  };

  if (showResult) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
          <h2 className="text-3xl font-bold text-center mb-6">Hoàn thành bài kiểm tra!</h2>
          <p className="text-xl text-center mb-6">
            Điểm số: {score}/{questions.length}
          </p>
          <button
            onClick={resetQuiz}
            className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
            aria-label="Restart Quiz"
          >
            Làm lại
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 relative">
      {/* Floating Menu Button */}
      {isSidebarOpen === false && (
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="fixed top-20 left-4 z-50 bg-blue-500 text-white p-3 rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300"
        >
          <FiMenu className="text-xl" />
        </button>
      )}

      {/* Floating Sidebar */}
      <div
        className={`fixed left-0 top-0 h-full bg-white shadow-xl transition-transform duration-300 ease-in-out z-40 w-64 overflow-y-auto transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        <div className="p-4 space-y-2 mt-16">
          <h2 className="text-xl font-bold mb-4">Questions</h2>
          {questions.map((_, index) => (
            <button
              key={index}
              onClick={() => scrollToQuestion(index)}
              className={`w-full flex items-center space-x-2 p-2 rounded-lg transition-colors ${currentQuestion === index ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                }`}
            >
              <FaCircle className={`text-sm ${selectedAnswers[index] ? 'text-green-500' : 'text-gray-400'}`} />
              <span>Câu số {index + 1}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-xl p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Bài kiểm tra</h1>
            <div className="flex items-center space-x-2">
              <FiClock className="text-xl text-gray-600" />
              <span className="text-lg font-semibold">{formatTime(timeLeft)}</span>
            </div>
          </div>
          {!isQuizSubmitted && (
            <button
              onClick={submitQuiz}
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300 mb-4"
            >
              Nộp bài
            </button>
          )}
        </div>

        {questions.map((question, questionIndex) => (
          <div id={`question-${questionIndex}`} key={questionIndex} className="bg-white rounded-lg shadow-xl p-6 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                Câu số {questionIndex + 1}: {question.question}
              </h2>
              {question?.hint && !isQuizSubmitted && (
                <button
                  onClick={() => toggleHint(questionIndex)}
                  className="flex items-center space-x-2 text-yellow-600 hover:text-yellow-700 transition-colors"
                >
                  <FaLightbulb />
                  <span>Gợi ý</span>
                </button>
              )}
            </div>

            {showHints[questionIndex] && question.hint && !isQuizSubmitted && (
              <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-4">
                <p className="text-yellow-800">
                  <span className="font-bold">Gợi ý:</span> {question.hint}
                </p>
              </div>
            )}
            {question?.image && (
              <img
                src={`${question.image}`}
                alt="Question visualization"
                className="w-full h-auto object-cover rounded-lg mb-4"
                onError={(e) => {
                  e.target.src = 'https://images.unsplash.com/photo-1606326608606-aa0b62935f2b';
                }}
              />
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              {question?.options?.map((option, optionIndex) => (
                <button
                  key={optionIndex}
                  onClick={() => handleAnswerSelect(questionIndex, option)}
                  className={`p-4 rounded-lg border-2 border-gray-200 hover:border-blue-500 transition duration-300 ${getButtonClass(
                    questionIndex,
                    option
                  )}`}
                  disabled={isQuizSubmitted}
                >
                  <div className="flex items-center justify-between">
                    <span>{option}</span>
                    {isQuizSubmitted && option === question.correctAnswer && (
                      <IoMdCheckmark className="text-white text-xl" />
                    )}
                    {isQuizSubmitted &&
                      option === selectedAnswers[questionIndex] &&
                      option !== question.correctAnswer && <IoMdClose className="text-white text-xl" />}
                  </div>
                </button>
              ))}
            </div>

            {isQuizSubmitted && question?.explanation && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <p className="font-semibold text-gray-700">Giải thích:</p>
                <p className="text-gray-600">{question.explanation}</p>
              </div>
            )}
          </div>
        ))}

        {isQuizSubmitted && (
          <div className="bg-white rounded-lg shadow-xl p-6">
            <h2 className="text-2xl font-bold text-center mb-4">Kết quả bài kiểm tra</h2>
            <p className="text-xl text-center mb-6">
              Điểm của bạn: {score}/{questions.length}
            </p>
            <button
              onClick={resetQuiz}
              className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Làm lại
            </button>
          </div>
        )}
      </div>

      {/* Overlay when sidebar is open */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={() => setIsSidebarOpen(false)} />
      )}
    </div>
  );
};

export default QuizGameEasy;
