import { Navigate, Route, Routes } from "react-router-dom";
// import MenuBar from "../layout/menuBar";
import { useSelector } from "react-redux";
import MathEquationGame from "../pages/Common/MathEquationGame";
import GameTypesGrid from "../pages/Common/GameTypesGrid";
import QuizGameEasy from "../pages/Common/QuizGameEasy";
import EssayQuizGameEasy from "../pages/Common/EssayQuizGameEasy";
import RankingScore from "../pages/rankings/RankingScore";
import GuongMatTieuBieu from "../pages/Common/guongMatTieuBieu";
import CrossWord from "../pages/Common/CrossWord";
import PageNotFound from "../pages/404";
import FAQPage from "../pages/Common/FAQS";
import EducationTopicPage from "../pages/Common/HomePage";
import LayoutCommon from "../components/LayoutCommon/LayoutCommon";
import PuzzleGame from "../pages/Common/PuzzleGame";


const UserRoute = () => {
  const admin = useSelector(state => state.user.info);
  const routes = [
    { path: "/puzzle-game", component: <PuzzleGame /> },
    { path: "/math-equation-game", component: <MathEquationGame /> },
    { path: "/quiz-game", component: <QuizGameEasy /> },
    { path: "/essay-quiz-game", component: <EssayQuizGameEasy /> },
    { path: "/rankingscore", component: <RankingScore /> },
    { path: "/guong-mat-tieu-bieu", component: <GuongMatTieuBieu /> },
    { path: "/cross-word", component: <CrossWord /> },
    { path: "/faqs", component: <FAQPage /> },
    { path: "/cuoc-thi", component: <GameTypesGrid /> },
    { path: "/", component: <EducationTopicPage /> },
  ];

  return (
    <Routes>
      <Route element={<LayoutCommon />}>
        <Route path="/" element={<EducationTopicPage />} />
        {routes.map((item, key) => (
          <Route element={item.component} path={item.path} key={key} />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}

export default UserRoute;
