export const Compare = (str1, str2) => {
    try {

        if (normalizeString(str1) === normalizeString(str2)) {
            return true;
        }

        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

function normalizeString(input) {
    // Chuyển về chữ thường
    let normalized = input.toString().toLowerCase();

    // Bỏ dấu và các ký tự không cần thiết
    normalized = normalized
        .normalize('NFD') // Chuyển đổi thành dạng chuẩn
        .replace(/[\u0300-\u036f]/g, '') // Bỏ dấu
        .replace(/[^\w\s]/g, ''); // Bỏ dấu câu và ký tự không phải chữ cái hoặc số

    // Loại bỏ khoảng trắng thừa
    normalized = normalized.trim().replace(/\s+/g, ' ');

    return normalized;
}