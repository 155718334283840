import React, { useState, useEffect } from "react";
import { FiMenu, FiX, FiUser, FiLogOut, FiSettings, FiUsers, FiDatabase, FiServer, FiShield, FiActivity } from "react-icons/fi";
import { Outlet } from "react-router-dom";

const AdminDashboard = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [activeMenuItem, setActiveMenuItem] = useState("");

    useEffect(() => {
        // setActiveMenuItem

        const href = window.location.href.split("/").pop();
        setActiveMenuItem(href);

    }, [window.location]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const menuItems = [
        { id: "dashboard", href: "/admin/dashboard", label: "Dashboard", icon: <FiActivity /> },
        // { id: "users", label: "User Management", icon: <FiUsers /> },
        // { id: "servers", label: "Server Controls", icon: <FiServer /> },
        { id: "database", href: "/admin/database", label: "Dữ liệu", icon: <FiDatabase /> },
        // { id: "security", label: "Security", icon: <FiShield /> },
        // { id: "settings", label: "Settings", icon: <FiSettings /> }
    ];

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <aside
                className={`${isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"} 
        fixed lg:relative lg:translate-x-0 z-40 w-64 h-full bg-gray-800 transition-transform duration-300 ease-in-out`}
            >
                <div className="flex items-center justify-center h-16 bg-gray-900">
                    <img
                        src="images.unsplash.com/photo-1667489022797-ab608913feeb"
                        alt="Logo"
                        className="h-8 w-auto"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23ccc'/%3E%3C/svg%3E";
                        }}
                    />
                </div>
                <nav className="mt-5 px-2">
                    {menuItems.map((item) => (
                        <button
                            key={item.id}
                            onClick={() => {
                                window.location.href = item.href;
                                setActiveMenuItem(item.id)
                            }}
                            className={`w-full flex items-center px-4 py-2 mt-2 text-sm transition-colors duration-200 ${activeMenuItem === item.id
                                ? "bg-gray-700 text-white"
                                : "text-gray-400 hover:bg-gray-700 hover:text-white"
                                } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"`}
                            aria-label={item.label}
                        >
                            <span className="mr-3">{item.icon}</span>
                            {item.label}
                        </button>
                    ))}
                </nav>
            </aside>

            {/* Main Content */}
            <div className="flex-1 flex flex-col overflow-hidden">
                {/* Header */}
                <header className="bg-white shadow-lg">
                    <div className="flex items-center justify-between px-6 py-4">
                        <button
                            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                            className="lg:hidden text-gray-600 focus:outline-none"
                            aria-label="Toggle menu"
                        >
                            {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                        </button>

                        <div className="flex items-center space-x-4 ml-auto">
                            <span className="text-gray-600">
                                {currentTime.toLocaleString()}
                            </span>
                            <div className="flex items-center space-x-2">
                                <FiUser className="text-gray-600" />
                                <span className="text-gray-800">Admin User</span>
                            </div>
                            <button
                                className="flex items-center text-red-600 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 rounded-lg px-3 py-1"
                                aria-label="Logout"
                            >
                                <FiLogOut className="mr-2" />
                                Logout
                            </button>
                        </div>
                    </div>
                </header>

                {/* Main Content Area */}
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
                    <div className="rounded-lg bg-white min-h-full p-3">
                        <Outlet />
                    </div>
                </main>
            </div>

            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
                <div
                    className="fixed inset-0 bg-gray-600 bg-opacity-50 lg:hidden"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}
        </div>
    );
};

export default AdminDashboard;