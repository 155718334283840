import React, { useCallback, useEffect, useState } from "react";
import { Space, Table, Tag } from 'antd';
// import { getApi } from "../../services/fetchData";
// import { useAppStore } from "../../store/appstate";
// import { useAuthStore } from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/imgs/backgr.jpg";
import { motion, AnimatePresence } from "framer-motion";

// import {
//     TopicQuestionEnum,
//     TopicQuestionNameEnum,
// } from "../../config/Enum/Question";

const RankingScore = () => {
    const navigate = useNavigate();
    // const auth = useAuthStore((store) => store.auth);
    // const { listQuestions } = useAppStore();

    const getRankColor = (rank) => {
        switch (rank) {
            case 1:
                return "bg-yellow-500";
            case 2:
                return "bg-gray-400";
            case 3:
                return "bg-yellow-700";
            default:
                return "bg-blue-600";
        }
    };

    const columns = [
        {
            title: "Hạng",
            dataIndex: "key",
            key: "key",
            width: 100,
        },
        {
            title: "Tên người dùng",
            dataIndex: "Name",
            key: "Name",
        },
        {
            title: "Tài khoản người dùng",
            dataIndex: "userName",
            key: "userName",
        },
        // {
        //     title: "Chủ đề",
        //     dataIndex: "topicSetQuestion",
        //     key: "topicSetQuestion",
        // },
        // {
        //     title: "Bộ đề",
        //     dataIndex: "topicSetNumber",
        //     key: "topicSetNumber",
        // },
        {
            title: "Điểm số",
            dataIndex: "pointNumber",
            key: "pointNumber",
        },
        // {
        //     title: "Thời gian",
        //     dataIndex: "time",
        //     key: "time",
        // },
    ];

    const [dataTable, setDataTable] = useState([]);

    const handleGetNamePropertyObjectByCondition = (
        object,
        objectName,
        value
    ) => {
        if (object) {
            for (let item in object) {
                if (object.hasOwnProperty(item) && object[item] === value) {
                    for (let i in TopicQuestionNameEnum) {
                        if (i === item) {
                            return objectName[i];
                        }
                    }
                }
            }
        }
    };


    const handleGetResultUser = useCallback(async () => {
        try {
            // const result = await getApi(`results10`);
            const result = [];
            console.log(result.data);
            if (result && result.data) {
                const data = result.data
                    .map((result, index) => {
                        return {
                            key: index + 1,
                            userName: result.user.account,
                            pointNumber: result.maxPointNumber,
                            Name: result.user.name,
                        }
                    })
                setDataTable(data);
            } else {
                console.log('Lỗi')
            }
        } catch (error) {
            console.log(error);
        }
    }, [auth, navigate]);

    useEffect(() => {
        handleGetResultUser();
    }, [handleGetResultUser]);

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
                role="dialog"
                aria-modal="true"
            >
                <motion.div
                    initial={{ scale: 0.9, y: 20 }}
                    animate={{ scale: 1, y: 0 }}
                    exit={{ scale: 0.9, y: 20 }}
                    className="bg-white rounded-xl shadow-2xl w-full max-w-3xl p-6 relative"
                >
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-bold flex items-center gap-2">
                            BẢNG XẾP HẠNG
                        </h2>
                    </div>

                    <div className="space-y-4 overflow-y-auto h-full max-h-[500px]"> {/* Set a max height */}
                        {dataTable.map((user) => (
                            <motion.div
                                key={user?.key}
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.3 }}
                                className="bg-white border border-gray-200 rounded-lg p-4 flex items-center justify-between hover:shadow-md transition-shadow duration-300"
                            >
                                <div className="flex items-center space-x-4">
                                    <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white font-bold ${getRankColor(user.key)}`}>
                                        {user?.key}
                                    </div>
                                    <img
                                        src={user?.avatar || "https://www.gravatar.com/avatar/?d=identicon"}
                                        // alt={user?.Name}
                                        className="w-12 h-12 rounded-full object-cover border-2 border-gray-200"
                                    />
                                    <div>
                                        <h3 className="font-semibold text-lg">{user.Name}</h3>
                                        {/* <p className="text-sm text-gray-500">{user.category}</p> */}
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className="font-bold text-lg">{user.pointNumber.toLocaleString()}</div>
                                    <div className="text-sm text-gray-500">points</div>
                                </div>
                            </motion.div>
                        ))}
                    </div>


                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={() => {
                                window.location.href = "/";
                            }}
                            className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors duration-300"
                        >
                            Đóng
                        </button>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    )
}

export default RankingScore;