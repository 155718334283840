import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import { loginRequest } from "../../services/authRequest";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { setAppLoading } from "../../hooks/store/userSlice";
import { OS } from "../../utils/platform";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    userName: "",
    password: ""
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      if (value.length < 8) {
        setErrors((prev) => ({ ...prev, password: "Mật khẩu phải lớn hơn 8 kí tự" }));
      } else {
        setErrors((prev) => {
          const { password, ...rest } = prev;
          return rest;
        });
      }
    }

    if (name === "userName") {
      if (value.length < 2) {
        setErrors((prev) => ({ ...prev, userName: "Tài khoản phải lớn hơn 2 kí tự" }));
      } else {
        setErrors((prev) => {
          const { userName, ...rest } = prev;
          return rest;
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!errors.userName && !errors.password) {
      setIsLoading(true);
      // Simulating API call

      try {
        dispatch(setAppLoading(true));
        const { userName, password } = formData;

        const response = await loginRequest({ user_name: userName, password: password });

        const { status, message, data } = response;

        if (status == 0) {
          notification.error({ message: message || "Đăng nhập thất bại" });
          return;
        }

        const { user, refresh_token } = data;

        if (!user || !refresh_token) {
          throw (new Error("Thiếu dữ liệu"));
        }

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("refresh_token", refresh_token);

        notification.success({ message: "Đăng nhập thành công" });

        window.location.href = "/";

      } catch (error) {
        console.log(error);
        notification.error({ message: error?.message || "Đăng nhập thất bại" });
      } finally {
        setIsLoading(false);
        dispatch(setAppLoading(false));
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData({ ...formData, userName: suggestion });
    setSuggestions([]);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100 p-4">
      <div className="w-full max-w-md bg-white rounded-xl shadow-2xl p-8 transform transition-all duration-300 hover:scale-[1.02]">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Chào mừng trở lại</h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative">
            <label
              htmlFor="userName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Tài khoản
            </label>
            <input
              id="userName"
              name="userName"
              value={formData.userName}
              onChange={handleInputChange}
              className={`w-full px-4 py-3 rounded-lg border ${errors.userName ? "border-red-500" : "border-gray-300"} focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 outline-none`}
              placeholder="Nhập tài khoản"
              aria-invalid={errors.userName ? "true" : "false"}
              aria-describedby="userName-error"
              autoComplete="userName"
            />
            {suggestions.length > 0 && (
              <div className="absolute z-10 w-full bg-white border border-gray-200 rounded-lg mt-1 shadow-lg">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
            {errors.userName && (
              <p
                id="userName-error"
                className="mt-1 text-sm text-red-600"
                role="alert"
              >
                {errors.userName}
              </p>
            )}
          </div>

          <div className="relative">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Mật khẩu
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className={`w-full px-4 py-3 rounded-lg border ${errors.password ? "border-red-500" : "border-gray-300"} focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 outline-none`}
                placeholder="Nhập mật khẩu"
                aria-invalid={errors.password ? "true" : "false"}
                aria-describedby="password-error"
                autoComplete="current-password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </div>
            {errors.password && (
              <p
                id="password-error"
                className="mt-1 text-sm text-red-600"
                role="alert"
              >
                {errors.password}
              </p>
            )}
          </div>

          <button
            type="submit"
            disabled={isLoading || Object.keys(errors).length > 0}
            className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
          >
            {isLoading ? (
              <>
                <FaSpinner className="animate-spin" size={20} />
                <span>Đăng đăng nhập...</span>
              </>
            ) : (
              <span>Đăng nhập</span>
            )}
          </button>
        </form>

        <div className="mt-6 text-center">
          <a
            href="/register"
            className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
          >
            Chưa có tài khoản?
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;