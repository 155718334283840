import { createSlice } from '@reduxjs/toolkit';
import { generateKey } from '../../helpers/hepler';
import { logoutRequest } from '../../services/authRequest';

const userData = () => {
  const data = localStorage.getItem('user');
  const device_id = localStorage.getItem('device_id');

  if (!device_id) {
    const newDeviceId = generateKey();
    localStorage.setItem('device_id', newDeviceId);
  }

  try {
    return data ? JSON.parse(data) : null;
  } catch (e) {
    return null;
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: !!userData(),
    info: userData(),
    isLoad: false,
  },
  reducers: {
    setLogin: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.isLoggedIn = true;
      state.info = action.payload;
    },
    setAuthUpdate: (state, action) => {
      const newData = { ...action.payload, token: state.info.token };
      localStorage.setItem('user', JSON.stringify(newData));
      state.info = newData;
    },
    setLogout: (state) => {
      logoutRequest()
        .then(() => { })
        .catch(() => { })
        .finally(() => {
          localStorage.removeItem('user');
          localStorage.removeItem('refresh_token');
          window.location.href = "/login";
        })

      state.isLoggedIn = false;
      state.info = null;
    },
    setAppLoading: (state, action) => {
      state.isLoad = action.payload;
    },
  },
});

const { actions, reducer } = userSlice;
export const { setLogin, setLogout, setAppLoading, setAuthUpdate } = actions;
export default reducer;
