import { DELETE_FILE, GET_ALL_FILE, IMPORT_DATA, UPLOAD_FILE, } from "../const/apiEndPoint";
import request from "../config/axiosConfig";


export const getAllFile = async (data) => {
    const response = await request({
        url: GET_ALL_FILE,
        method: 'POST',
        data
    });

    return response?.data;
};

export const uploadFile = async (data) => {
    const response = await request({
        url: UPLOAD_FILE,
        method: 'POST',
        data
    });

    return response?.data;
};

export const deleteFile = async (data) => {
    const response = await request({
        url: DELETE_FILE,
        method: 'POST',
        data
    });

    return response?.data;
};

export const importData = async (dataParam) => {
    const response = await request({
        url: IMPORT_DATA,
        method: 'POST',
        data: dataParam
    });

    return response?.data;
};

