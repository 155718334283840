import axios from 'axios';
import { notification } from 'antd';
import { currentBrowser, OS } from '../utils/platform';
import { generateKey } from '../helpers/hepler';
import { RefeshSession } from '../services/authRequest';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const request = axios.create({
  baseURL: API_URL,
  timeout: 50000
});

const userLogout = () => {
  notification.error({ message: 'Phiên đăng nhập hết hạn! Vui lòng đăng nhập lại.' })
  localStorage.removeItem('user');
  localStorage.removeItem("refresh_token");
  window.location.href = '/login';
}

const handleError = async error => {
  const originalConfig = error.config;
  if (originalConfig.url.search('/auth/') === -1 && error.response) {
    if ([402, 401].includes(error.response.status) && !originalConfig._retry) {
      originalConfig._retry = true;
      //todo refresh login
      //tạm thời chưa làm refresh login thì cho logout luôn
      userLogout();
    } else if (error.response.status === 401) {
      userLogout();
    }
  }
  return Promise.reject(error?.response || error);
};

const handleSuccess = async res => {
  // Kiểm tra status
  if (res.data.status === -2) {
    try {
      // Gọi API để lấy session mới
      const newSessionResponse = await RefeshSession();

      // Kiểm tra nếu lấy session thành công
      if (newSessionResponse?.data.status == 1) {
        // Lưu trữ thông tin phiên mới nếu cần thiết
        localStorage.setItem('refresh_token', newSessionResponse?.data?.data?.refresh_token);
        localStorage.setItem('user', JSON.stringify(newSessionResponse.data.data.user));
        // Gọi lại API cũ với thông tin phiên mới
        const originalConfig = res.config;
        originalConfig.headers.refresh_token = newSessionResponse.data?.data.refresh_token;
        const retryResponse = await axios(originalConfig); // Gọi lại API trước đó
        return retryResponse; // Trả về phản hồi mới
      } else {
        userLogout();
        notification.error({ message: res?.data?.message || "Có lỗi xảy ra" });
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: 'Error refreshing session.' });
    }
  }

  // Kiểm tra mã lỗi 404
  if (res.data?.code === 404) {
    notification.error({ message: `${res.config.url} ${res.data?.message || 'API not found'}` });
  }

  return res; // Trả về phản hồi ban đầu nếu không có điều gì xảy ra
};


const handleRequest = async config => {
  const { headers } = config;
  let requestConfig = config;
  const user = localStorage.getItem('user');

  let device_id = localStorage.getItem("device_id");

  const platform = OS();
  const browser = currentBrowser();

  if (!device_id) {
    const newDeviceId = generateKey();
    localStorage.setItem('device_id', newDeviceId);
    device_id = newDeviceId;
  }

  requestConfig.headers = {
    ...headers,
    device_id: device_id,
    platform: platform,
    browser: browser
  };


  if (user) {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      requestConfig.headers.refresh_token = refresh_token;
    } catch (e) {
      localStorage.removeItem('user');
    }
  }

  return requestConfig;
};

request.interceptors.response.use(handleSuccess, handleError);
request.interceptors.request.use(handleRequest, error => Promise.reject(error));

export default request;
