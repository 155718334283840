// lib/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"; // Dùng nếu bạn sử dụng Firebase Storage
import { getFirestore } from "firebase/firestore"; // Dùng nếu bạn sử dụng Firestore

const firebaseConfig = {
    apiKey: "AIzaSyDMoU0pPm352FkZYA7aWzRa0Yu2wvz9E18",
    authDomain: "chunhandatviet-km.firebaseapp.com",
    projectId: "chunhandatviet-km",
    storageBucket: "chunhandatviet-km.appspot.com",
    messagingSenderId: "609511393989",
    appId: "1:609511393989:web:e7d80f8158591d9a623eac",
    measurementId: "G-3HR3Y0673Q"
};

// Khởi tạo Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { storage, firestore };
