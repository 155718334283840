import { useState, useEffect, useMemo } from 'react';
import { FiChevronDown, FiMenu, FiX, FiSettings } from 'react-icons/fi';
import { IoSchoolOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../hooks/store/userSlice';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user.info);

  const menuItems = [
    {
      id: 'home',
      label: 'TRANG CHỦ',
      link: '/',
    },
    {
      id: 'rank',
      label: 'BẢNG XẾP HẠNG',
      link: '/guong-mat-tieu-bieu',
    },
    {
      id: 'exam',
      label: 'CUỘC THI',
      link: 'cuoc-thi',
      // dropdownItems: [
      //     { id: "quiz-game", label: "Truy tìm cổ vật", link: "quiz-game" },
      //     { id: "essay-quiz-game", label: "Giải mã kho báu", link: "essay-quiz-game" },
      //     { id: "cross-word", label: "Khám phá kì quan", link: "cross-word" },
      //     { id: "puzzle-game", label: "Vượt chướng ngại vật", link: "puzzle-game" },
      //     { id: "math-equation-game", label: "Tìm kiếm kì quan", link: "math-equation-game" },
      // ]
    },
    {
      id: 'faqs',
      label: 'FAQs',
      link: '/faqs',
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLogin = () => {
    window.location.href = '/login';
  };

  const handleLogout = () => {
    dispatch(setLogout());
    setIsUserDropdownOpen(false);
  };

  const handleDropdownToggle = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  const UserAvatar = ({ user }) => (
    <img
      src={user?.avatar || 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3'}
      alt="User avatar"
      className="w-8 h-8 rounded-full object-cover"
      onError={(e) => {
        e.target.src = 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3';
      }}
    />
  );

  return (
    <header className="top-0 left-0 right-0 bg-white shadow-md z-50">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <IoSchoolOutline className="text-3xl text-blue-600" />
            <span className="text-xl font-bold text-gray-800">EduPlatform</span>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center space-x-6">
            {menuItems.map((item) => (
              <div key={item.id} className="relative">
                {item.dropdownItems ? (
                  <>
                    <button
                      className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
                      onMouseEnter={() => handleDropdownToggle(item.id)}
                      aria-expanded={activeDropdown === item.id}
                      aria-haspopup="true"
                    >
                      {item.label}
                      <FiChevronDown className="ml-1" />
                    </button>

                    {activeDropdown === item.id && (
                      <div
                        className="absolute top-full left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-50"
                        role="menu"
                        aria-orientation="vertical"
                        onMouseLeave={() => handleDropdownToggle(null)}
                      >
                        {item.dropdownItems.map((dropdownItem) => (
                          <a
                            key={dropdownItem.id}
                            href={dropdownItem.link}
                            className="block px-4 py-2 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors"
                            role="menuitem"
                            onClick={() => {
                              console.log(dropdownItem);
                            }}
                          >
                            {dropdownItem.label}
                          </a>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <a
                    href={item.link}
                    className="text-gray-600 hover:text-blue-600 transition-colors"
                    aria-label={item.label}
                  >
                    {item.label}
                  </a>
                )}
              </div>
            ))}

            {!isLoggedIn ? (
              <button
                onClick={handleLogin}
                className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                aria-label="Login"
              >
                ĐĂNG NHẬP
              </button>
            ) : (
              <div className="relative">
                <button
                  className="flex items-center space-x-2 focus:outline-none"
                  onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                  aria-expanded={isUserDropdownOpen}
                >
                  <UserAvatar user={user} />
                  <span className="text-gray-700">{user?.full_name}</span>
                  <FiChevronDown className="text-gray-500" />
                </button>

                {isUserDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-50">
                    {user?.is_admin && (
                      <a
                        href="/admin"
                        className="flex items-center px-4 py-2 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors"
                      >
                        <FiSettings className="mr-2" />
                        QUẢN LÝ
                      </a>
                    )}
                    <button
                      onClick={handleLogout}
                      className="w-full text-left px-4 py-2 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors"
                    >
                      ĐĂNG XUẤT
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="lg:hidden text-gray-600 hover:text-blue-600 transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-expanded={isMenuOpen}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <FiX className="text-2xl" /> : <FiMenu className="text-2xl" />}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="lg:hidden mt-4 pb-4">
            <div className="flex flex-col space-y-4">
              {menuItems.map((item) => (
                <div key={item.id}>
                  {item.dropdownItems ? (
                    <>
                      <button
                        className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
                        onClick={() => handleDropdownToggle(item.id)}
                      >
                        {item.label}
                        <FiChevronDown className="ml-1" />
                      </button>

                      {activeDropdown === item.id && (
                        <div className="mt-2 space-y-2 pl-4">
                          {item.dropdownItems.map((dropdownItem) => (
                            <a
                              key={dropdownItem.id}
                              href={dropdownItem.link}
                              className="block text-gray-600 hover:text-blue-600 transition-colors"
                            >
                              {dropdownItem.label}
                            </a>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <a href={item.link} className="text-gray-600 hover:text-blue-600 transition-colors">
                      {item.label}
                    </a>
                  )}
                </div>
              ))}

              {!isLoggedIn ? (
                <button
                  onClick={handleLogin}
                  className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors w-full"
                >
                  ĐĂNG NHẬP
                </button>
              ) : (
                <div className="space-y-2">
                  <div className="flex items-center space-x-2 px-2">
                    <UserAvatar user={user} />
                    <span className="text-gray-700">{user?.full_name}</span>
                  </div>
                  {user?.is_admin && (
                    <a
                      href="/admin"
                      className="flex items-center px-2 py-2 text-gray-700 hover:text-blue-600 transition-colors"
                    >
                      <FiSettings className="mr-2" />
                      QUẢN LÝ
                    </a>
                  )}
                  <button
                    onClick={handleLogout}
                    className="w-full text-left px-2 py-2 text-gray-700 hover:text-blue-600 transition-colors"
                  >
                    ĐĂNG XUẤT
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Scroll to Top Button */}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          aria-label="Scroll to top"
        >
          ↑
        </button>
      )}
    </header>
  );
};

export default Header;
