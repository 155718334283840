import React, { useState, useEffect, useMemo } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { motion } from 'framer-motion';
import { IMG_SOURCE } from '../../constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getQuestions, updateLearn } from '../../services/commonApiRequest';
import { useDispatch } from 'react-redux';
import { setAppLoading } from '../../hooks/store/userSlice';

const MathEquationGame = () => {
  //   const allLandmarks = {
  //     image: 'okee',
  //     data: [
  //       { id: 1, question: '1', answer: 'Cổng văn miếu', isPlaced: false },
  //       { id: 2, question: '2', answer: 'Khuê Văn Các', isPlaced: false },
  //       { id: 3, question: '3', answer: 'Nhà bia Tiến Sĩ', isPlaced: false },
  //       { id: 4, question: '4', answer: 'Khu Đại Thành', isPlaced: false },
  //       { id: 5, question: '5', answer: 'Khu Thái Học', isPlaced: false },
  //     ],
  //   };

  const itemsPerPage = window.innerWidth < 768 ? 3 : 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [landmarks, setLandmarks] = useState([]);
  const [correctPlacements, setCorrectPlacements] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const dispatch = useDispatch();

  const location = useLocation();
  let { catergory_id } = location?.state || {};

  useEffect(() => {
    // console.log(catergory_id)
    if (totalScore === allLandmarks?.data?.length && totalScore !== 0) {
      dispatch(setAppLoading(true));
      updateLearn({ catergory_id: catergory_id, point: 100 })
        .then()
        .catch()
        .finally(() => {
          dispatch(setAppLoading(false));
        });
    }

  }, [totalScore, correctPlacements])

  const shuffledArray = useMemo(() => {
    if (landmarks) {
      const array = [...landmarks];

      let shuffled = [...array]; // Tạo một bản sao của mảng
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Chọn một chỉ số ngẫu nhiên
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Đổi chỗ
      }
      return shuffled;
    }

    return [];

  }, [landmarks])

  const {
    data: data,
    refetch,
    isLoading,
  } = useQuery(['data', catergory_id], () => getQuestions({ catergory_id: catergory_id }), {
    enabled: catergory_id !== null,
  });

  const allLandmarks = useMemo(() => {
    if (data?.data) {
      const { options, question, image } = data?.data?.[0];

      let mapData = {};
      let newData = [];

      options.forEach((item, index) => {
        newData.push({ id: index, question: question[index], answer: item, isPlaced: false });
      });

      mapData.image = image;
      mapData.data = newData;

      setLandmarks(newData?.slice(0, itemsPerPage));
      return mapData;
    }
    return {};
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      const newItemsPerPage = window.innerWidth < 768 ? 3 : 6;
      const startIndex = (currentPage - 1) * newItemsPerPage;
      const endIndex = startIndex + newItemsPerPage;

      setLandmarks(allLandmarks?.data?.slice(startIndex, endIndex));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentPage, data]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setLandmarks(allLandmarks?.data?.slice(startIndex, endIndex));
  }, [currentPage]);

  const playCorrectSound = () => {
    const audio = new Audio('https://assets.mixkit.co/sfx/preview/mixkit-correct-answer-tone-2870.mp3');
    audio.play();
  };

  const DraggableAnswer = ({ landmark }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'answer',
      item: { id: landmark.id, answer: landmark.answer },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    return (
      <motion.div
        ref={drag}
        whileHover={{ scale: 1.1 }}
        className={`px-2 sm:px-4 py-1 sm:py-2 bg-blue-500 rounded-lg flex items-center justify-center text-white font-bold cursor-move text-sm sm:text-base ${isDragging ? 'opacity-50' : 'opacity-100'
          } ${landmark.isPlaced ? 'invisible' : 'visible'}`}
        style={{ minWidth: '80px', sm: { minWidth: '120px' } }}
      >
        {landmark.answer}
      </motion.div>
    );
  };

  const DropZone = ({ landmark }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: 'answer',
      drop: (item) => handleDrop(item.id, landmark.id),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }));

    const handleDrop = async (droppedId, targetId) => {
      if (droppedId === targetId) {
        setLandmarks(landmarks?.map((lm) => (lm.id === droppedId ? { ...lm, isPlaced: true } : lm)));
        setCorrectPlacements((prev) => prev + 1);
        setTotalScore((prev) => prev + 1);
        playCorrectSound();
      }
    };

    return (
      <div
        ref={drop}
        className={`px-2 sm:px-4 py-1 sm:py-2 border-2 border-dashed rounded-lg flex items-center justify-center ${isOver ? 'border-green-500' : 'border-gray-300'
          }`}
        style={{ minWidth: '80px', minHeight: '32px', sm: { minWidth: '120px', minHeight: '40px' } }}
      >
        {landmarks?.find((lm) => lm.id === landmark.id && lm.isPlaced) && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="w-full h-full bg-green-500 rounded-lg flex items-center justify-center text-white font-bold text-sm sm:text-base px-2 sm:px-4 py-1 sm:py-2"
          >
            {landmark.answer}
          </motion.div>
        )}
      </div>
    );
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < allLandmarks?.data?.length) {
      setCurrentPage((prev) => prev + 1);
      setCorrectPlacements(0);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setCorrectPlacements(0);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="min-h-screen bg-gray-100 p-4 sm:p-8">
        <div className="max-w-6xl mx-auto bg-white rounded-xl shadow-lg p-4 sm:p-8">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-4 sm:mb-8 space-y-4 sm:space-y-0">
            <h1 className="text-xl sm:text-3xl font-bold text-center sm:text-left">Trò Chơi Địa Danh Việt Nam</h1>
            <div className="text-lg sm:text-xl font-bold text-blue-600">
              Tổng điểm: {totalScore}/{allLandmarks?.data?.length}
            </div>
          </div>

          <div className="flex flex-col space-y-4 sm:space-y-8">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8">
              <div className="w-full sm:w-1/2 flex justify-center items-center">
                <img src={allLandmarks?.image} alt="Vietnam landmarks" className="w-full object-cover rounded-lg" />
              </div>
              <div className="w-full sm:w-1/2 bg-gray-50 p-4 sm:p-6 rounded-lg">
                <h2 className="text-lg sm:text-xl font-semibold mb-4">Các Đáp Án</h2>
                <div className="flex flex-wrap gap-2 sm:gap-4 justify-center sm:justify-start">
                  {shuffledArray?.map((landmark) => (
                    <DraggableAnswer key={landmark.id} landmark={landmark} />
                  ))}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              {landmarks?.map((landmark) => (
                <div key={landmark.id} className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                  <div className="text-base sm:text-lg font-semibold mb-2 sm:mb-3">{landmark.question}</div>
                  <DropZone landmark={landmark} />
                </div>
              ))}
            </div>

            <div className="bg-gray-50 p-4 sm:p-6 rounded-lg">
              <h2 className="text-lg sm:text-xl font-semibold mb-2">Tiến Độ Hiện Tại</h2>
              <div className="flex items-center space-x-2">
                <div className="flex-1 bg-gray-200 rounded-full h-3 sm:h-4">
                  <div
                    className="bg-green-500 rounded-full h-3 sm:h-4 transition-all duration-500"
                    style={{ width: `${(correctPlacements / landmarks?.length) * 100}%` }}
                  ></div>
                </div>
                <span className="font-bold text-sm sm:text-base">
                  {Math.round((correctPlacements / landmarks?.length) * 100)}%
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-4 sm:mt-6">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className={`px-3 sm:px-4 py-1 sm:py-2 rounded-lg text-sm sm:text-base ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
              >
                Câu hỏi trước
              </button>
              <div className="text-base sm:text-lg font-semibold">
                Trang {currentPage}/{Math.ceil(allLandmarks?.data?.length / itemsPerPage)}
              </div>
              <button
                onClick={handleNextPage}
                disabled={currentPage * itemsPerPage >= allLandmarks?.data?.length}
                className={`px-3 sm:px-4 py-1 sm:py-2 rounded-lg text-sm sm:text-base ${currentPage * itemsPerPage >= allLandmarks?.data?.length
                  ? 'bg-gray-300'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
              >
                Câu hỏi tiếp
              </button>
            </div>

            {correctPlacements === landmarks?.length && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="bg-green-100 p-4 sm:p-6 rounded-lg text-center"
              >
                <h3 className="text-xl sm:text-2xl font-bold text-green-700">Chúc Mừng!</h3>
                <p className="text-green-600 text-sm sm:text-base">Bạn đã hoàn thành phần này!</p>
                {currentPage * itemsPerPage < allLandmarks?.data?.length && (
                  <button
                    onClick={handleNextPage}
                    className="mt-3 sm:mt-4 px-4 sm:px-6 py-1 sm:py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 text-sm sm:text-base"
                  >
                    Tiếp tục
                  </button>
                )}
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default MathEquationGame;
