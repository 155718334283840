import { Layout } from "antd";
import Footer from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";

export default function LayoutCommon() {
    return (
        <div className="min-h-screen flex flex-col justify-between">
            <div className="w-full">
                <Header />
            </div>
            <div className="w-full">
                <Outlet />
            </div>
            <div className="w-full">
                <Footer />
            </div>
        </div>
    )
}