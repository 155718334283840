import { Link } from "react-router-dom";
import { Button, Result } from "antd";


const PageNotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button onClick={() => {
        window.location.href = "/"
      }} type="primary">Quay lại trang chủ</Button>}
    />
  )
}

export default PageNotFound;
