import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/css/style.css';
import Loading from './components/Loading/index.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';
import UserRoute from './routes/userRoute.js';
import AdminRoute from './routes/adminRoute.js';
import LoginPage from './pages/Auth/Login.js';
import RegisterPage from './pages/Auth/Register.js';
import { notification } from 'antd';

const firebaseConfig = {
  apiKey: 'AIzaSyDMoU0pPm352FkZYA7aWzRa0Yu2wvz9E18',
  authDomain: 'chunhandatviet-km.firebaseapp.com',
  projectId: 'chunhandatviet-km',
  storageBucket: 'chunhandatviet-km.appspot.com',
  messagingSenderId: '609511393989',
  appId: '1:609511393989:web:e7d80f8158591d9a623eac',
  measurementId: 'G-3HR3Y0673Q',
};

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error) => {
          console.log(error);
          notification.error({
            message: error.data?.message || 'Có lỗi xảy ra vui lòng thử lại sau!',
          });
        },
      },
    },
  });

  const isLoading = useSelector((state) => state.user.isLoad);
  const user = useSelector((state) => state.user.info);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {isLoading == true && (
          <div className="w-full h-full flex justify-center items-center fixed-loader">
            <Loading />
          </div>
        )}

        <Routes>
          {/* Đường dẫn cho người dùng chưa đăng nhập */}
          {!isLoggedIn && (
            <>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
            </>
          )}

          {/* Route cho người dùng đã đăng nhập */}
          {isLoggedIn && (
            <>
              {/* {user?.is_admin && <Route path="/admin/*" element={<AdminRoute />} />} */}
              <Route path="/admin/*" element={<AdminRoute />} />
            </>
          )}
          <Route path="*" element={<UserRoute />} />

          {/* Route cho trang không tìm thấy */}
        </Routes>
      </BrowserRouter>
    </QueryClientProvider >
  );
}

export default App;
