import React, { useMemo, useState } from 'react';
import { FaChess, FaGamepad, FaDice, FaFootballBall, FaVolumeUp, FaVolumeMute, FaTimes, FaPlay } from 'react-icons/fa';
import { getCategories } from '../../services/commonApiRequest';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setAppLoading } from '../../hooks/store/userSlice';
import { useNavigate } from 'react-router-dom';

const GameTypesGrid = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [showRule, setShowRule] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gamesPerPage = 6;
  // const gameTypes = [
  //     {
  //         id: 1,
  //         name: "Game Chiến Thuật",
  //         description: "Thử thách trí óc của bạn với các quyết định chiến lược phức tạp",
  //         image: "images.unsplash.com/photo-1611996575749-79a3a250f948",
  //         // icon: <FaChess className="text-3xl" />,
  //         longDescription: "Trải nghiệm game chiến thuật đỉnh cao với nhiều thử thách và nhiệm vụ hấp dẫn. Xây dựng đế chế, phát triển quân đội và chinh phục các vùng đất mới."
  //     },
  //     {
  //         id: 2,
  //         name: "Game Phiêu Lưu Hành Động",
  //         description: "Bắt đầu cuộc hành trình đầy hứng khởi và thú vị",
  //         image: "images.unsplash.com/photo-1542751371-adc38448a05e",
  //         // icon: <FaGamepad className="text-3xl" />,
  //         longDescription: "Khám phá thế giới rộng lớn với nhiều nhiệm vụ phiêu lưu. Chiến đấu với quái vật, thu thập tài nguyên và nâng cấp nhân vật của bạn."
  //     },
  //     {
  //         id: 3,
  //         name: "Game Cờ Bàn",
  //         description: "Các trò chơi bàn cờ cổ điển được làm mới cho trải nghiệm kỹ thuật số",
  //         image: "images.unsplash.com/photo-1606503153255-59d8b2e0d1b9",
  //         // icon: <FaDice className="text-3xl" />,
  //         longDescription: "Tham gia các ván cờ gay cấn với người chơi từ khắp nơi trên thế giới. Rèn luyện kỹ năng và trở thành bậc thầy cờ vua."
  //     },
  //     {
  //         id: 4,
  //         name: "Game Thể Thao",
  //         description: "Trải nghiệm cảm giác thú vị của các môn thể thao đối kháng",
  //         image: "images.unsplash.com/photo-1461896836934-ffe607ba8211",
  //         // icon: <FaFootballBall className="text-3xl" />,
  //         longDescription: "Tham gia các giải đấu thể thao hấp dẫn. Quản lý đội bóng, lên chiến thuật và giành chiến thắng trong các trận đấu gay cấn."
  //     },
  //     {
  //         id: 5,
  //         name: "Game Giải Đố",
  //         description: "Kiểm tra kỹ năng giải quyết vấn đề của bạn",
  //         image: "images.unsplash.com/photo-1628277613967-6abca504d0ac",
  //         // icon: <FaGamepad className="text-3xl" />,
  //         longDescription: "Thử thách trí tuệ với hàng trăm câu đố hấp dẫn. Nâng cao tư duy logic và khả năng giải quyết vấn đề."
  //     },
  //     {
  //         id: 6,
  //         name: "Game Đua Xe",
  //         description: "Tăng tốc qua các đường đua thú vị và thử thách",
  //         image: "images.unsplash.com/photo-1548195667-1d329af0a472",
  //         // icon: <FaGamepad className="text-3xl" />,
  //         longDescription: "Tham gia các cuộc đua xe gay cấn trên nhiều địa hình khác nhau. Nâng cấp xe của bạn và trở thành tay đua số một."
  //     }
  // ];

  const { data: data, refetch, isLoading } = useQuery(['data'], () => getCategories());

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const {
    data: dataSub,
    refetch: refetchSub,
    isLoading: isLoadSub,
  } = useQuery(['dataSub', selectedGame], () => getCategories({ parent_id: selectedGame.id }), {
    enabled: selectedGame !== null,
  });

  const gameTypes = useMemo(() => {
    if (!data) {
      return [];
    }
    return data?.data || [];
  }, [data]);

  const indexOfLastGame = currentPage * gamesPerPage;
  const indexOfFirstGame = indexOfLastGame - gamesPerPage;
  const currentGames = gameTypes.slice(indexOfFirstGame, indexOfLastGame);
  const totalPages = Math.ceil(gameTypes.length / gamesPerPage);

  const handleCardClick = (gameId) => {
    const game = gameTypes.find((g) => g.id === gameId);
    setSelectedGame(game);
  };

  const handleKeyPress = (event, gameId) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleCardClick(gameId);
    }
  };

  const toggleSound = () => {
    setIsMuted(!isMuted);
  };

  const closeModal = () => {
    setSelectedGame(null);
  };

  const startGame = (gameId) => {
    if (!isLoggedIn) {
      window.location.href = "/login";
      return;
    }
    navigate(selectedGame?.screen || '/', {
      state: { catergory_id: gameId },
    });
    closeModal();
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">Các Loại Game</h1>
        {/* <button
                    onClick={toggleSound}
                    className="p-2 rounded-full hover:bg-gray-100"
                    aria-label={isMuted ? "Bật âm thanh" : "Tắt âm thanh"}
                >
                    {isMuted ? <FaVolumeMute className="text-2xl" /> : <FaVolumeUp className="text-2xl" />}
                </button> */}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentGames.map((game) => (
          <div
            key={game.id}
            onClick={() => handleCardClick(game.id)}
            onKeyPress={(e) => handleKeyPress(e, game.id)}
            tabIndex="0"
            className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
            role="button"
            aria-label={`Chọn ${game.name}`}
          >
            <div className="relative h-48 overflow-hidden">
              <img
                src={`https://${game.image}`}
                alt={game.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.src = 'https://images.unsplash.com/photo-1550745165-9bc0b252726f';
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
            </div>
            <div className="p-6">
              <div className="flex items-center gap-3 mb-3">
                {game.icon}
                <h3 className="text-xl font-semibold text-gray-800">{game.name}</h3>
              </div>
              <p className="text-gray-600">{game.description}</p>
            </div>
          </div>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="mt-8 flex justify-center gap-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
            <button
              key={pageNum}
              onClick={() => setCurrentPage(pageNum)}
              className={`px-4 py-2 rounded-md ${currentPage === pageNum ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } transition-colors duration-200`}
              aria-label={`Đến trang ${pageNum}`}
            >
              {pageNum}
            </button>
          ))}
        </div>
      )}

      {selectedGame && !showRule && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="relative">
              <img
                src={`https://${selectedGame.image}`}
                alt={selectedGame.name}
                className="w-full h-64 object-cover"
                onError={(e) => {
                  e.target.src = 'https://images.unsplash.com/photo-1550745165-9bc0b252726f';
                }}
              />
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
                aria-label="Đóng"
              >
                <FaTimes className="text-xl" />
              </button>
            </div>
            <div className="p-6">
              <div className="flex items-center gap-3 mb-4">
                {selectedGame.icon}
                <h2 className="text-2xl font-bold text-gray-800">{selectedGame.name}</h2>
              </div>
              <div>
                {selectedGame?.longDescription &&
                  selectedGame.longDescription.split('\n').map((item, index) => {
                    return (
                      <p key={index} className="text-gray-600 mb-2">
                        {item}
                      </p>
                    );
                  })}
              </div>

              <div className="pt-3">
                <button
                  // onClick={() => startGame(selectedGame.id)}

                  onClick={() => {
                    setShowRule(true);
                  }}

                  className="w-full bg-blue-500 text-white py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-600 transition-colors duration-200"
                >
                  <FaPlay />
                  <span>Các bộ đề</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showRule && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="w-screen flex justify-center items-center h-screen">
            <div className="bg-white rounded-xl h-3/5 w-3/5 flex flex-col p-10 gap-4 justify-center overflow-y-auto">
              {dataSub?.data?.map((item, index) => {
                return <div key={index} className='flex justify-center'>
                  <button className='border p-4 rounded-lg bg-[#3F85FB] text-white'
                    onClick={() => {
                      startGame(item.id)
                    }}
                  >
                    {`${item?.name}`}
                  </button>
                </div>
              })}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default GameTypesGrid;
