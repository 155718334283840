import { useQuery } from "react-query";
import { getAllFile, importData } from "../../services/uploadApiRequest";
import { Button, Col, Form, Image, notification, Popconfirm, Row, Table, Upload } from "antd";
import { useMemo } from "react";
import { InboxOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setAppLoading } from "../../hooks/store/userSlice";
import Dragger from "antd/es/upload/Dragger";
import { PlusOutlined } from '@ant-design/icons';
import { handleDeleteToFirebase, handleUploadToFirebase } from "../../services/uploadFirebase";
export default function Database() {
    const dispatch = useDispatch();

    const handleUpload = async (file) => {
        dispatch(setAppLoading(true));
        //gửi dữ liệu qua formData:

        console.log(file.originFileObj)

        const formData = new FormData();

        // Kiểm tra và thêm tệp vào FormData
        if (file && file.originFileObj instanceof File) {
            console.log("Đối tượng File:", file.originFileObj); // Kiểm tra đối tượng File
            formData.append("file", file.originFileObj); // Thêm trực tiếp
        } else {
            notification.error({ message: "Đối tượng tệp không hợp lệ!" });
            dispatch(setAppLoading(false));
            return;
        }

        notification.info({ message: "Đang Import dữ liệu!" });
        const response = await importData(formData);

        if (response.status == 1) {
            notification.success({ message: "Import dữ liệu thành công!" });
        } else {
            notification.error({ message: response.message });
        }
        dispatch(setAppLoading(false));
    };

    const {
        data: data,
        refetch,
        isLoading,
    } = useQuery(['data'], () => getAllFile());

    async function handleUploadFiles(files) {
        dispatch(setAppLoading(true))
        await handleUploadToFirebase(files.fileList.map(item => item.originFileObj));
        dispatch(setAppLoading(false));
        refetch();
    }


    const dataViewTable = useMemo(
        () => data?.data?.map((data, index) => ({ ...data, stt: index + 1, key: index })),
        [data]
    );

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            width: 50,
        },
        {
            title: 'Image',
            dataIndex: 'url',
            width: 150,
        },
        {
            title: 'Chi tiết',
            dataIndex: 'type',
            width: 50,
            render: (type, record) => {
                switch (type) {
                    case "image":
                        return (
                            <div className="w-20 flex justify-center items-center">
                                <Image
                                    src={`${record.url}`}
                                    className='w-full'
                                    alt='Image'
                                />
                            </div>
                        )
                    case "audio":
                        return <div className="w-full"> {/* Thằng cha */}
                            <audio controls src={`${record.url}`}
                                className='w-full'>
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    default:
                        return (<div>
                            {/* {detail} */}
                        </div>)
                }
            }
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: 50,
            fixed: 'right',
            render: (_, record) => {
                return (
                    <div className='flex gap-3 items-center justify-center'>
                        <Popconfirm
                            title="Xoá"
                            description="Bạn có chắc muốn xoá?"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => handleDeleteToFirebase(record?.name, record?.id).then(result => {
                                refetch();
                            })}
                            okText="Có"
                            cancelText="Không"
                        >
                            <Button danger>Xoá</Button>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="flex flex-col gap-20">
            <div className="flex justify-between h-full">

                <Form
                    onFinish={(values) => {
                        handleUploadFiles(values.files);
                    }}
                >
                    <Col flex="1" className='flex justify-end items-center gap-2'>
                        <Form.Item
                            name="files"
                            rules={[{ required: true }]}
                        >
                            <Dragger multiple={true}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                    banned files.
                                </p>
                            </Dragger>

                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Tải lên
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>
                <Form
                    onFinish={(values) => {
                        handleUpload(values.databaseExcel.file);
                    }}
                >
                    <Row className='flex justify-end items-center gap-2'>
                        <Form.Item className="h-full">
                            <label>
                                Tải câu hỏi
                            </label>
                        </Form.Item>
                        <Form.Item
                            name="databaseExcel"
                        >
                            <Upload
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>

                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Import
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </div>

            <Table
                bordered
                dataSource={dataViewTable}
                loading={isLoading}
                columns={columns}
                scroll={{ x: 'calc(100vh)' }}
            />
        </div>
    )
}