import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BsFillBookmarkFill } from "react-icons/bs";

const FAQPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        {
            question: "Các chương trình giáo dục nào được cung cấp?",
            answer: "Chúng tôi cung cấp nhiều chương trình đào tạo bao gồm các khóa học trực tuyến, học trực tiếp, và chương trình học song ngữ. Các khóa học được thiết kế phù hợp với mọi lứa tuổi và trình độ.",
            context: "Chương trình được cập nhật thường xuyên theo xu hướng giáo dục hiện đại."
        },
        {
            question: "Thời gian học tập kéo dài bao lâu?",
            answer: "Thời gian học tập linh hoạt tùy theo từng khóa học. Thông thường, các khóa ngắn hạn kéo dài 2-3 tháng, trong khi các chương trình dài hạn có thể kéo dài từ 6 tháng đến 1 năm.",
            context: "Lịch học linh hoạt phù hợp với người đi làm."
        },
        {
            question: "Chi phí học tập như thế nào?",
            answer: "Học phí được tính dựa trên từng chương trình cụ thể. Chúng tôi có nhiều gói học phí phù hợp với khả năng tài chính của học viên, kèm theo các chương trình ưu đãi và học bổng.",
            context: "Nhiều phương thức thanh toán linh hoạt."
        },
        {
            question: "Có hỗ trợ học viên sau khi tốt nghiệp không?",
            answer: "Có, chúng tôi cung cấp dịch vụ hỗ trợ nghề nghiệp, tư vấn định hướng và kết nối với các doanh nghiệp đối tác sau khi học viên hoàn thành khóa học.",
            context: "Hỗ trợ 24/7 cho học viên."
        },
        {
            question: "Phương pháp giảng dạy như thế nào?",
            answer: "Chúng tôi áp dụng phương pháp giảng dạy tích cực, kết hợp giữa lý thuyết và thực hành. Học viên được tham gia các dự án thực tế và workshop chuyên môn.",
            context: "Phương pháp giảng dạy hiện đại và hiệu quả."
        },
        {
            question: "Làm thế nào để đăng ký học?",
            answer: "Học viên có thể đăng ký trực tuyến qua website hoặc đến trực tiếp văn phòng tư vấn. Quy trình đăng ký đơn giản và nhanh chóng.",
            context: "Tư vấn miễn phí trước khi đăng ký."
        }
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {/* Left Side - Accordions */}
                    <div className="space-y-4">
                        <h2 className="text-3xl font-bold text-blue-900 mb-6">Câu Hỏi Thường Gặp Về Giáo Dục</h2>
                        {faqData.map((faq, index) => (
                            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden border border-blue-100 hover:border-blue-300 transition-all duration-300">
                                <button
                                    onClick={() => toggleAccordion(index)}
                                    className="w-full px-6 py-4 flex items-center justify-between text-left focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    aria-expanded={activeIndex === index}
                                    aria-controls={`faq-content-${index}`}
                                >
                                    <span className="text-lg font-medium text-blue-900">{faq.question}</span>
                                    {activeIndex === index ? (
                                        <FaChevronUp className="h-5 w-5 text-blue-500" />
                                    ) : (
                                        <FaChevronDown className="h-5 w-5 text-gray-500" />
                                    )}
                                </button>
                                {activeIndex === index && (
                                    <div
                                        id={`faq-content-${index}`}
                                        className="px-6 py-4 bg-blue-50"
                                    >
                                        <p className="text-gray-700">{faq.answer}</p>
                                        <div className="mt-4 p-4 bg-blue-100 rounded-lg flex items-start space-x-3">
                                            <BsFillBookmarkFill className="h-5 w-5 text-blue-600 mt-1" />
                                            <p className="text-sm text-blue-700">{faq.context}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Right Side - Image and Content */}
                    <div className="space-y-8">
                        <div className="rounded-lg overflow-hidden shadow-lg">
                            <img
                                src="https://images.unsplash.com/photo-1523050854058-8df90110c9f1"
                                alt="Môi trường học tập"
                                className="w-full h-64 object-cover"
                            />
                        </div>

                        <div className="bg-white rounded-lg shadow-md p-6 space-y-4 border border-blue-100">
                            <h3 className="text-xl font-bold text-blue-900">Đội Ngũ Giảng Viên Chuyên Nghiệp</h3>
                            <p className="text-gray-600">
                                Đội ngũ giảng viên của chúng tôi có nhiều năm kinh nghiệm trong lĩnh vực giáo dục, được đào tạo bài bản và thường xuyên cập nhật phương pháp giảng dạy mới.
                            </p>
                        </div>

                        <div className="bg-white rounded-lg shadow-md p-6 space-y-4 border border-blue-100">
                            <h3 className="text-xl font-bold text-blue-900">Cam Kết Chất Lượng</h3>
                            <p className="text-gray-600">
                                Chúng tôi cam kết mang đến chất lượng giảng dạy tốt nhất, với phương pháp học tập hiệu quả và môi trường học tập chuyên nghiệp.
                            </p>
                        </div>

                        <button className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            Liên Hệ Tư Vấn Ngay
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQPage;