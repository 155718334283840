import { useState } from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube, FaGraduationCap, FaBook, FaChalkboardTeacher } from "react-icons/fa";

const Footer = () => {
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setEmail("");
    };

    return (
        <footer className="bg-blue-50 text-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="py-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-lg font-semibold mb-6 text-blue-700 flex items-center gap-2">
                            <FaGraduationCap className="text-2xl" />
                            Academic Programs
                        </h3>
                        <ul className="space-y-4">
                            <li><button className="hover:text-blue-600 transition-colors">Online Courses</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Certificate Programs</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Summer Schools</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Workshops</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Student Exchange</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Research Programs</button></li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-6 text-blue-700 flex items-center gap-2">
                            <FaBook className="text-2xl" />
                            Resources
                        </h3>
                        <ul className="space-y-4 mb-6">
                            <li><button className="hover:text-blue-600 transition-colors">Digital Library</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Research Papers</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Publications</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Academic Calendar</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Student Portal</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">E-Learning</button></li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-6 text-blue-700 flex items-center gap-2">
                            <FaChalkboardTeacher className="text-2xl" />
                            Student Support
                        </h3>
                        <ul className="space-y-4">
                            <li><button className="hover:text-blue-600 transition-colors">Academic Advisory</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Career Services</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Counseling</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Financial Aid</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">Health Services</button></li>
                            <li><button className="hover:text-blue-600 transition-colors">International Office</button></li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-6 text-blue-700">Connect With Us</h3>
                        <div className="flex space-x-4 mb-8">
                            <button className="text-2xl hover:text-blue-600 transition-colors" aria-label="Facebook"><FaFacebook /></button>
                            <button className="text-2xl hover:text-blue-600 transition-colors" aria-label="Twitter"><FaTwitter /></button>
                            <button className="text-2xl hover:text-blue-600 transition-colors" aria-label="Instagram"><FaInstagram /></button>
                            <button className="text-2xl hover:text-blue-600 transition-colors" aria-label="LinkedIn"><FaLinkedin /></button>
                            <button className="text-2xl hover:text-blue-600 transition-colors" aria-label="YouTube"><FaYoutube /></button>
                        </div>
                        <img
                            src="https://images.unsplash.com/photo-1472162072942-cd5147eb3902"
                            alt="Education Logo"
                            className="h-16 w-auto mb-4 rounded-lg shadow-md"
                        />
                    </div>
                </div>

                <div className="border-t border-blue-100 py-8">
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                        <p className="text-sm text-gray-600">&copy; 2024 Educational Institute. All rights reserved.</p>
                        <div className="flex space-x-6">
                            <button className="text-sm text-gray-600 hover:text-blue-600 transition-colors">Privacy Policy</button>
                            <button className="text-sm text-gray-600 hover:text-blue-600 transition-colors">Terms & Conditions</button>
                            <button className="text-sm text-gray-600 hover:text-blue-600 transition-colors">Accessibility</button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;