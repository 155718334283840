import { notification } from "antd";
import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiLock, FiUser, FiUsers } from "react-icons/fi";
import { registerRequest } from "../../services/authRequest";
import { setAppLoading } from "../../hooks/store/userSlice";
import { useDispatch } from "react-redux";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    userName: "",
    password: "",
    confirmPassword: ""
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Real-time validation
    switch (name) {
      case "fullName":
        if (value.length < 2) {
          setErrors({ ...errors, fullName: "Tên ít nhất phải chứa 2 kí tự" });
        } else {
          const { fullName, ...rest } = errors;
          setErrors(rest);
        }
        break;

      case "userName":
        if (value.length < 2) {
          setErrors({ ...errors, userName: "Tải khoản phải chứa 2 kí tự" });
        } else {
          const { userName, ...rest } = errors;
          setErrors(rest);
        }
        break;

      case "password":
        if (value.length < 8) {
          setErrors({ ...errors, password: "Mật khẩu phải chứa 8 kí tự" });
        } else {
          const { password, ...rest } = errors;
          setErrors(rest);
        }
        break;

      case "confirmPassword":
        if (value !== formData.password) {
          setErrors({ ...errors, confirmPassword: "Mật khẩu không khớp" });
        } else {
          const { confirmPassword, ...rest } = errors;
          setErrors(rest);
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0 &&
      formData.fullName &&
      formData.userName &&
      formData.password &&
      formData.confirmPassword) {

      setIsLoading(true);

      try {
        dispatch(setAppLoading(true));

        const { fullName, userName, password } = formData;

        const response = await registerRequest({ user_name: userName, password: password, full_name: fullName });

        const { status, message, data } = response;

        if (status == 0) {
          notification.error({ message: message || "Đăng ký thất bại" });
          return;
        }

        if (!data) {
          throw (new Error("Thiếu dữ liệu"));
        }

        if (data) {
          const { user, refresh_token } = data;

          if (!user || !refresh_token) {
            throw (new Error("Thiếu dữ liệu"));
          }

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("refresh_token", refresh_token);

          notification.success({ message: "Đăng ký thành công" });

          window.location.href = "/";
        }

      } catch (error) {
        console.log(error);
        notification.error({ message: error?.message || "Đăng ký thất bại" });
      } finally {
        setIsLoading(false);
        dispatch(setAppLoading(false));
      }
    }
  };

  const renderInput = (name, icon, type = "text", showName) => (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {showName.charAt(0).toUpperCase() + showName.slice(1).replace(/([A-Z])/g, " $1")}
      </label>
      <div className="mt-1 relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {icon}
        </div>
        <input
          id={name}
          name={name}
          type={type}
          autoComplete={name}
          required
          className={`appearance-none block w-full pl-10 pr-3 py-2 border ${errors[name] ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent`}
          placeholder={`Nhập ${showName}`}
          value={formData[name]}
          onChange={handleInputChange}
          aria-label={name}
          aria-invalid={errors[name] ? "true" : "false"}
        />
      </div>
      {errors[name] && (
        <p className="mt-2 text-sm text-red-600" role="alert">
          {errors[name]}
        </p>
      )}
    </div>
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100 p-4">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-2xl transform transition-all hover:scale-[1.01]">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-2">
            Tạo tài khoản mới
          </h2>
          <p className="text-center text-sm text-gray-600">
            Tham gia ngay! Vui lòng điền thông tin của bạn
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            {renderInput("fullName", <FiUser className="h-5 w-5 text-gray-400" />, "text", "họ tên")}
            {renderInput("userName", <FiUsers className="h-5 w-5 text-gray-400" />, "userName", "tài khoản")}
            {renderInput("password", <FiLock className="h-5 w-5 text-gray-400" />, "password", "mật khẩu")}
            {renderInput("confirmPassword", <FiLock className="h-5 w-5 text-gray-400" />, "password", "xác nhận mật khẩu")}
          </div>

          <button
            type="submit"
            disabled={isLoading || Object.keys(errors).length > 0}
            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white ${isLoading || Object.keys(errors).length > 0 ? "bg-indigo-400" : "bg-indigo-600 hover:bg-indigo-700"} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200`}
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
            ) : (
              "Đăng ký"
            )}
          </button>
        </form>

        <div className="text-center">
          <p className="text-sm text-gray-600">
            Đã có tài khoản?{" "}
            <a
              href="/login"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Đăng nhập
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;