import { useState } from 'react';
import { storage } from '../lib/firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { UPLOAD_FILE } from '../const/apiEndPoint';
import request from '../config/axiosConfig';
import { deleteFile } from './uploadApiRequest';
// import { deleteFile } from './apiRequest';

export const handleUploadToFirebase = async (files) => {
    if (!files || files.length === 0) return;

    const uploadPromises = Array.from(files).map(file => {
        const storageRef = ref(storage, `uploads/${file.name}`);

        return uploadBytes(storageRef, file)
            .then(async (snapshot) => {

                // Lấy URL download của file đã upload
                const downloadURL = await getDownloadURL(storageRef);
                return {
                    url: downloadURL,
                    type: file.type.split("/")[0],
                    name: file.name
                };
            })
            .catch(error => {
                console.error("Upload failed", error);
                throw error;
            });
    });

    // Chờ tất cả các promise hoàn thành
    return Promise.all(uploadPromises)
        .then(results => {
            createRowUpload(results);
            return { status: 1, message: "Thành công" };
        })
        .catch(error => {
            console.error("Some uploads failed", error);
            return { status: 0, message: error.message };
        });
};

export const handleDeleteToFirebase = async (fileName, id) => {
    try {
        const fileRef = ref(storage, `uploads/${fileName}`); // Đường dẫn đến file

        await deleteFile({ id });

        // Xóa file
        await deleteObject(fileRef);

        console.log(`File ${fileName} deleted successfully!`);
    } catch (error) {
        console.error("Error deleting file:", error);
    }
}

async function createRowUpload(data) {
    return request({
        url: UPLOAD_FILE,
        method: 'POST',
        data: { data }
    });
}
