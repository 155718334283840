import { GET_CATEGORIES, GET_QUESTIONS, GET_RANK, UPDATE_LEARN, } from "../const/apiEndPoint";
import request from "../config/axiosConfig";


export const getCategories = async (data) => {
    const response = await request({
        url: GET_CATEGORIES,
        method: 'POST',
        data
    });

    return response?.data;
};

export const getQuestions = async (data) => {
    const response = await request({
        url: GET_QUESTIONS,
        method: 'POST',
        data
    });

    return response?.data;
};

export const updateLearn = async (data) => {
    const response = await request({
        url: UPDATE_LEARN,
        method: 'POST',
        data
    });

    return response?.data;
};

export const getRank = async (data) => {
    const response = await request({
        url: GET_RANK,
        method: 'POST',
        data
    });

    return response?.data;
};