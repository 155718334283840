
import { LOGIN, LOGOUT, REFESH_SESSION, REGISTER, UPDATE_INFO, VERIFICATION } from "../const/apiEndPoint";
import request from "../config/axiosConfig";


export const loginRequest = async (data) => {
  const response = await request({
    url: LOGIN,
    method: 'POST',
    data
  });

  return response?.data;
};

export const registerRequest = async (data) => {
  const response = await request({
    url: REGISTER,
    method: 'POST',
    data
  });

  return response?.data;
};

export const logoutRequest = () => {
  return request({
    url: LOGOUT,
    method: 'POST'
  });
};

export const updateInfoRequest = (data) => {
  return request({
    url: UPDATE_INFO,
    method: 'POST',
    data
  });
};

export const verificationRequest = (data) => {
  return request({
    url: VERIFICATION,
    method: 'POST',
    data
  });
};


export const RefeshSession = (data) => {
  return request({
    url: REFESH_SESSION,
    method: 'POST',
    data
  });
};